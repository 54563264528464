





























































































































































import { Component, Vue } from 'vue-property-decorator';

import {
  dispatchDeletePlugsCheckerTask,
  dispatchGetPlugsCheckerTasks,
  dispatchKillPlugsCheckerTask,
  dispatchRunPlugsCheckerTask,
  dispatchStatPlugsCheckerTasks,
  dispatchDownloadPlugsCheckerTaskResult,
  dispatchDownloadPlugsCheckerTaskFile,
} from '@/store/plugs-checker-tasks/actions';
import { readPlugsCheckerTasks } from '@/store/plugs-checker-tasks/getters';
import { AVITO_TASK_UPDATE_RATE } from '../../../constants';
import { estimateTimeToGo } from '../utils';
import { IPlugsCheckerTaskStats } from '@/interfaces/plugs-checker-tasks';
import ProgressBar from '@/components/ProgressBar.vue';
import TooltipBase from '@/components/TooltipBase.vue';

@Component({
  components: {
    ProgressBar,
    TooltipBase,
  },
})
export default class PlugsCheckerTasks extends Vue {
  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Имя',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Описание',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Менеджер',
      sortable: true,
      value: 'manager',
      align: 'left',
    },
    // {
    //   text: 'Глубина',
    //   sortable: true,
    //   value: 'deep',
    //   align: 'left',
    // },
    {
      text: 'Создано',
      sortable: true,
      value: 'date_create',
      align: 'left',
    },
    {
      text: 'Завершено',
      sortable: true,
      value: 'date_end',
      align: 'left',
    },
    {
      text: 'Статус',
      sortable: true,
      value: 'status',
      align: 'left',
    },
    {
      text: 'Действия',
      value: 'id',
    },
  ];

  public updateTimer?: number = undefined;

  get tasks() {
    return readPlugsCheckerTasks(this.$store);
  }

  public tableLoaded = false;

  public async mounted() {
    await dispatchGetPlugsCheckerTasks(this.$store);
    await dispatchStatPlugsCheckerTasks(this.$store);
    this.tableLoaded = true;

    this.updateTimer = setInterval(async () => {
      await dispatchGetPlugsCheckerTasks(this.$store);
      await dispatchStatPlugsCheckerTasks(this.$store);
      }, AVITO_TASK_UPDATE_RATE,
    );
  }

  public destroyed() {
    clearInterval(this.updateTimer);
    this.updateTimer = undefined;
  }

  public async deleteTask(taskId: number) {
    await dispatchDeletePlugsCheckerTask(this.$store, {id: taskId});
    await dispatchGetPlugsCheckerTasks(this.$store);
  }

  public async runTask(taskId: number) {
    await dispatchRunPlugsCheckerTask(this.$store, {id: taskId});
  }

  public async killTask(taskId: number) {
    await dispatchKillPlugsCheckerTask(this.$store, {id: taskId});
  }

  public async downloadPlugsCheckerTaskResult(taskId: number, result: string) {
    await dispatchDownloadPlugsCheckerTaskResult(this.$store, { id: taskId, result });
  }

  public async downloadPlugsCheckerTaskFile(taskFile: string) {
    await dispatchDownloadPlugsCheckerTaskFile(this.$store, { taskFile });
  }

  public showEstimateTimeToGo(total: number, started: number, current: number, startedTime: Date) {
    if (current >= total || started === undefined || startedTime === undefined) return '';
    return `Еще ${estimateTimeToGo(total, started, current, startedTime)}`;
  }

  public getPercent(statData: IPlugsCheckerTaskStats, divider = 'found_count', divisible = 'total_count') {
    if (!statData[divisible]) return 0;
    return Math.floor(statData[divider] / statData[divisible] * 100);
  }

}
